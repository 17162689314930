@import '../../../../../styles/customMediaQueries.css';

.priorityLinkWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.priorityLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.highlight {
  /* color: var(--marketplaceColor);
  &:hover {
    color: var(--marketplaceColorDark);
  } */
}

.priorityLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
  text-wrap: nowrap;
}

.menuContainer {
  & .priorityLinkLabel {
    text-decoration: none !important;
    color: var(--colorGrey700);
  }
  &:hover {
    border-bottom: unset !important;
    & .priorityLinkLabel {
      color: var(--colorBlack);
    }
  }
  &:hover .subMenu {
    display: block;
  }
  & .subMenu {
    display: none;
    background-color: var(--colorWhite);
    position: absolute;
    left: 0;
    top: calc(var(--topbarHeight) + 1px);
    border-top: 1px solid var(--colorGrey700);
    width: 100vw;
    height: 80vh;
    & a {
      padding: 0;
      border: none;
    }
    & .priorityLinkLabel {
      padding: 3px 0;
      margin: 0;
      width: 100%;
      &:hover {
        color: var(--colorGrey500);
      }
    }
  }
}

.menuHeader {
  color: var(--colorGrey700);
}

.subMenuContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  margin: 48px 0;
}

.lists {
  display: flex;
  flex-direction: row;
  gap: 48px;
}

.list {
}

.images {
  display: flex;
  flex-direction: row;
  gap: 48px;
  & .image {
    width: auto;
    height: calc(80vh - 96px);
  }
}
